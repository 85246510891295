import React from "react";
import Loadable from "react-loadable";
import withRoleRequired from '../../lib/withRoleRequired';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Spinner from "../../components/deliberative-survey/Spinner";

const LoadableInterestChart = Loadable({
  loader: () => import("../../components/deliberative-survey/InterestChart"),
  loading() {
    return (
      <>
        <div className='col-span-7'/>
        <div className='m-auto col-span-7'>
          <Spinner/>
        </div>
      </>
    );
  }
});

const Interest: React.FunctionComponent = () => (
  <Layout pageHeader="Interest in Defence"
    breadcrumbs={[{ label: 'Attitudes Towards Defence', link: '/deliberative-survey' }]}
    moreInfo = "deliberative-survey/interest-in-defence"
  >
    <SEO
      keywords={[`future`, `workforce`, `modelling`, `tool`]}
      title="Attitudes Towards Defence Interest in Defence"
    />
    <div className="px-4 py-4 mx-auto md:px-8 md:py-4 h-full">
      <div className = "grid grid-cols-8 grid-flow-row gap-1 h-full grid-rows-interest">
        <div className = 'col-span-8 pb-4'>
          This page allows you to see the most popular responses that were provided as reasons to work or not work for Defence (NB. for full details of all of the responses and their importance see the <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href='/evidence-catalogue/Deliberative%20Survey.pdf'>Deliberative Survey report</a>). The responses can be viewed in relation to whether they apply to Regular Armed Forces, Reserve Forces or Civil Service, whether they were provided by the young adult group or parents, and whether they were reasons for or against joining. Use the radio buttons to select the view you would like to see and hover over the bars themselves to see the full version of the reason. Some additional qualitative insights are available for some of the factors based upon separate focus group workshops that were held. If available, these will appear on the right-hand side.
        </div>
        <LoadableInterestChart/>
      </div>
    </div>

  </Layout>
)

export default withRoleRequired(Interest);
